// 项目详情页
<template>
  <div class="sld_project_detail">
    <!-- <MemberTitle :memberTitle="L['项目详情']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L['项目详情'] }}</h3>

      <div class="block5 flex-col" v-if="projectInfo.data">
        <div class="section2 flex-row">
          <span class="info13">{{ projectInfo.data.projectName }}</span>
        </div>
        <div class="section3 flex-row">
          <span class="info14"> {{ L['创建时间：'] }}{{ projectInfo.data.createTime }}</span>
          <span class="word14">
            {{ L['项目周期：'] }}
            {{ projectInfo.data.startTime ? projectInfo.data.startTime.split(' ')[0] : '' }}
            ～
            {{ projectInfo.data.endTime ? projectInfo.data.endTime.split(' ')[0] : '' }}
          </span>
        </div>
        <div class="section4 flex-row">
          <span class="word15">{{ projectInfo.data.description }}</span>
        </div>
      </div>

      <!-- 顶部标题 start-->
      <div class="cart_title flex_row_start_center">
        <div class="cart_title_pre flex_row_start_center">
          <el-checkbox v-model="allCheck" @change="changeAll"></el-checkbox>
          <span style="margin-left: 18px">{{ L['图片'] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L['产品信息'] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L['描述'] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L['生产状态'] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L['价格'] }}</span>
        </div>
        <div class="cart_title_pre">
          <span>{{ L['购买数量'] }}</span>
        </div>
      </div>
      <!-- 顶部标题 end-->

      <!-- 列表 start -->
      <div class="cart_lists" v-if="projectInfo.data.productList && projectInfo.data.productList.length > 0">
        <div class="cart_lists_pre" v-for="(item, index) in projectInfo.data.productList" :key="index">
          <div class="goods_list flex-row">

            <!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
            <div class="goods_pre_sel">
              <el-checkbox v-model="item.ischeck" @change="changeSelect(item, $event)"></el-checkbox>
            </div>
            <div class="section8">
              <div class="section8_img" :style="`background-image:url(${item.goodsImage})`"
                @click="goodsDetail(item.productId)"></div>
            </div>
            <div class="section8 flex-col">
              <div class="txt19 txt191" @click="goodsDetail(item.productId)">{{ item.goodsName }}</div>
              <div class="txt19 txt191" @click="goodsDetail(item.productId)" style="margin-top:5px;">{{
                item.specValues
              }}
              </div>
              <br />
              <div class="txt19 txt191" @click="goodsDetail(item.productId)" v-if="item.orderCode">
                {{ L['订货编码：'] }}{{ item.orderCode }}</div>

            </div>
            <div class="section8 txt19_box flex-col">
              <el-scrollbar>
                <div class="flex-col">
                  <span class="txt19" v-for="(items, index) in item.attributeList" :key="index">
                    {{ items.attributeName }}：{{ items.attributeValue }}
                  </span>
                  <span v-show="item.attributeList.length == 0" style="text-indent:20px;line-height:92px;">--</span>
                </div>
              </el-scrollbar>
            </div>
            <div class="section8 flex-col">
              <span class="txt20">{{ item.produceStateValue }}</span>
              <span class="info23">{{ L['发货日：'] }}{{ item.deliverTime }}{{ L['天'] }}</span>
            </div>
            <div class="section8 flex-col">
              <span class="txt20 txt_price" v-if="item.goodsPrice">{{ L['￥'] }}{{ item.goodsPrice.toFixed(2) }}</span>
              <div style="width: 130px;" class="flex_row_center_center" v-else>
                <div class=" type2"></div>
              </div>

            </div>
            <div class="section9 flex-col">
              <div class="goods_edit_nem flex_row_center_center">
                <span @click="editNum('reduce', item, $event)">-</span>
                <input type="number" v-model="item.goodsNum" @input="editNum('edit', item, $event)"
                  @blur="editNum('blur', item, $event)" />
                <span @click="editNum('add', item, $event)">+</span>
                <span class="goods_edit_nem_after">{{ item.saleUnit }}</span>
              </div>
              <div class="section9_desc">
                <span>{{ L['起订量: '] }}{{ item.minBuyNum }}</span>
                <span class="desc">{{ item.saleUnitDes }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cart_lists_pre" v-for="(item, index) in projectInfo.data.invalidList" :key="index">
          <div class="goods_list flex-row gray">
            <!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
            <div class="goods_pre_sel unuse">
              <span>{{ L['失效'] }}</span>
            </div>
            <div class="section8">
              <div class="section8_img" :style="`background-image:url(${item.goodsImage})`"
                @click="goodsDetail(item.productId)"></div>
            </div>
            <div class="section8 flex-col"><span class="txt19 txt191" @click="goodsDetail(item.productId)">{{
              item.goodsSpec
            }}</span></div>
            <div class="section8 txt19_box flex-col">
              <el-scrollbar>
                <div class="flex-col">
                  <span class="txt19" v-for="(items, index) in item.attributeList" :key="index">
                    {{ items.attributeName }}：{{ items.attributeValue }}
                  </span>
                  <span v-show="item.attributeList.length == 0" style="text-indent:20px;line-height:92px;">--</span>
                </div>
              </el-scrollbar>
            </div>
            <div class="section8 flex-col">
              <span class="txt20">{{ item.produceStateValue }}</span>
              <span class="info23">{{ L['发货日：'] }}{{ item.deliverTime }}{{ L['天'] }}</span>
            </div>
            <div class="section8 flex-col">
              <span class="txt20 txt_price" v-if="item.goodsPrice">{{ L['￥'] }}{{ item.goodsPrice.toFixed(2) }}</span>
              <div style="width: 130px;" class="flex_row_center_center" v-else>
                <div class=" type2"></div>
              </div>

            </div>
            <div class="section9 flex-col">
              <div class="goods_edit_nem flex_row_center_center">
                <span class="disabled">-</span>
                <input disabled type="number" v-model="item.goodsNum" @input="editNum('edit', item, $event)"
                  @blur="editNum('blur', item, $event)" />
                <span class="disabled">+</span>
                <span class="goods_edit_nem_after">{{ item.saleUnit }}</span>
              </div>
              <div class="section9_desc">
                <span>{{ L['起订量:'] }} {{ item.minBuyNum }}</span>
                <span class="desc">{{ item.saleUnitDes }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SldCommonEmpty v-else :tip="L['暂无产品数据']" totalWidth="934px" />
      <!-- 列表 end -->
    </div>
    <!-- 操作按钮 start -->
    <div class="options_btn flex_row_between_center" ref="optionsBtn"
      v-if="projectInfo.data.productList && projectInfo.data.productList.length > 0">
      <div class="options_btn_left flex_row_start_center">
        <div class="options_sel">
          <el-checkbox v-model="allCheck" @change="changeAll"></el-checkbox>

          <span>{{ L['全选'] }}</span>
        </div>
        <div class="del_all cursor_pointer" @click="outPut">
          {{ L['导出已选产品'] }}
        </div>
        <div class="del_all cursor_pointer" @click="delProduct(1)"
          v-if="projectInfo.data.productList && projectInfo.data.productList.length > 0">
          {{ L['删除已选产品'] }}
        </div>
        <div class="del_all cursor_pointer" @click="delProduct(2)"
          v-if="projectInfo.data.invalidList && projectInfo.data.invalidList.length > 0">
          {{ L['清空无效产品'] }}
        </div>
      </div>
      <div class="options_right flex_row_start_center">
        <div class="options_sel_num">
          {{ L['已选择'] }}{{ selectNum }}{{ L['件产品'] }}
        </div>
        <div class="options_line"></div>
        <div class="options_sel_price flex-row" v-if="isShowTotal">
          <div class="options_all_count flex_column_start_end">
            <span>{{ L['合计'] }}：</span>
          </div>
          <div class="options_promotion flex_column_start_end">
            <span>{{ L['￥'] }}{{ totalMoney }}</span>
          </div>
        </div>
        <div class="go_buy cursor_pointer" :class="{ active: selectNum > 0 }" @click="confirmOrder"
          v-if="memberInfo.memberType == 2">
          {{ L['提交需求'] }}
        </div>
        <div class="go_buy cursor_pointer" :class="{ active: selectNum > 0 }" @click="goBuy">
          {{ L['立即购买'] }}
        </div>
      </div>
    </div>
    <!-- 操作按钮 end -->
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import SldCommonEmpty from '@/components/SldCommonEmpty';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: "project-detail",
  components: {
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const memberInfo = store.state.memberInfo;
    const projectId = ref(route.query.id);
    const isEdit = ref(route.query.edit ? true : false);
    const projectInfo = reactive({ data: {} });
    const allCheck = ref(false);
    const productInfos = ref('');
    const selectNum = ref(0);
    const totalMoney = ref(0);
    const isPaying = ref(false);
    const goodsInfos = ref('');
    const invalidIds = ref('');
    const isShowTotal = ref(true)
    const getInfo = () => {
      let param = {
        projectId: projectId.value
      }
      proxy.$get('v3/member/front/project/detail', param)
        .then(res => {
          if (res.state == 200) {
            let ids = [];
            res.data.productList = []; //产品列表
            res.data.invalidList = []; //失效列表
            if (res.data.projectGoodsList.length > 0) {
              res.data.projectGoodsList.map(item => {
                if (item.state != 3) {
                  res.data.invalidList.push(item);
                  ids.push(item.bindId);
                } else {
                  item.ischeck = false;
                  res.data.productList.push(item);
                }
              })
              invalidIds.value = ids.join(',');
            }
            projectInfo.data = res.data;
          }
        })
    }

    //商品数量的编辑
    const editNum = (type, item, e) => {
      let currentSpecNum = item.goodsNum;
      let minBuyNum = item.minBuyNum ? item.minBuyNum : 1;

      let maxMin = Math.min(99999, item.productStock)


      if (type == "add") {
        currentSpecNum++;
        if (currentSpecNum > maxMin) {
          currentSpecNum = maxMin;
          ElMessage.warning(L['超过购买限制!']);
        }
      } else if (type == "edit") {
        currentSpecNum = e.currentTarget.value.replace(/\D/g, "");
        if (currentSpecNum > maxMin) {
          ElMessage.warning(L['超过购买限制!']);
          currentSpecNum = maxMin;
        }
      } else if (type == 'blur') {
        currentSpecNum = e.currentTarget.value;
        if (currentSpecNum <= minBuyNum) {
          currentSpecNum = minBuyNum;
          ElMessage.warning(L['最低限购'] + minBuyNum + L['件']);
        } else {
          currentSpecNum = currentSpecNum.replace(/\D/g, "");
          if (currentSpecNum > maxMin) {
            ElMessage.warning(L['超过购买限制!']);
            currentSpecNum = maxMin;
          }
        }
      } else if (type == "reduce") {
        if (currentSpecNum > minBuyNum) {
          currentSpecNum--;
        } else {
          currentSpecNum = minBuyNum;
          ElMessage.warning(L['最低限购'] + minBuyNum + L['件']);
        }
      }
      item.goodsNum = currentSpecNum
      editGoodsNum(item);
    };

    const editGoodsNum = (item) => { //编辑商品数量
      let param = {
        bindId: item.bindId,
        num: item.goodsNum,
        productId: item.productId
      }
      proxy.$post('v3/member/front/project/editGoodsNum', param)
        .then(res => {
          if (res.state == 200) {
            checkSelect();
          } else {
            ElMessage.error(res.msg)
          }
        })
    };

    const changeAll = () => { //全选切换
      checkSelect(allCheck.value ? 1 : 2);
    }
    const changeSelect = (item, e) => { //商品选择切换
      if (item.goodsNum > item.productStock) {
        ElMessage(L['库存不足'])
        item.ischeck = false
        return
      }
      checkSelect();
    }
    const checkSelect = (type) => { //筛选选中的商品 type: 1 全选 2 全不选
      let arr = [];
      let num = 0;
      let useNum = 0;
      let total = 0;
      let goods = [];
      let priceList = []
      projectInfo.data.productList.forEach(item => {
        useNum++;
        if (type == 1) {
          item.ischeck = true;
          num++;
          total += item.goodsPrice * item.goodsNum
          arr.push(item.productId + '-' + item.goodsNum);
          goods.push(item.productId + '-' + item.goodsNum);
          priceList.push(item.goodsPrice)
        } else if (type == 2) {
          item.ischeck = false;
        } else if (item.ischeck) {
          num++;
          total += item.goodsPrice * item.goodsNum
          arr.push(item.productId + '-' + item.goodsNum);
          goods.push(item.productId + '-' + item.goodsNum);
          priceList.push(item.goodsPrice)
        }


      })

      isShowTotal.value = priceList.every(i => i > 0)


      if (!type) {
        allCheck.value = (num == useNum ? true : false)
      }
      selectNum.value = num;
      totalMoney.value = total == 0 ? 0 : total.toFixed(2);
      productInfos.value = arr.length > 0 ? arr.join(',') : '';

      goodsInfos.value = goods;
    }

    const confirmOrder = () => { //提交清单
      if (selectNum.value == 0) {
        return;
      } else if (isPaying.value) {
        return;
      }
      isPaying.value = true;
      let param = {
        productInfos: productInfos.value,
        projectId: projectId.value
      }
      proxy.$post('v3/member/front/member/purchase/apply/submit', param)
        .then(res => {
          if (res.state === 200) {
            ElMessage.success(res.msg);
            setTimeout(() => {
              isPaying.value = false;
              if (window.history.state.back) {
                router.back();
              } else {
                router.replace({ path: '/member/project/list' })
              }
            }, 1000)
          } else {
            isPaying.value = false;
            ElMessage.error(res.msg);
          }
        })
    };

    const goBuy = () => { //立即购买
      if (selectNum.value == 0) {
        return;
      }
      let query = {
        productSets: encodeURIComponent(productInfos.value)
      }

      proxy.$post("v3/business/front/orderOperate/confirm", {
        products: productInfos.value,
        source: 3
      }, 'json').then(res => {
        if (res.state == 200) {
          router.push({
            path: "/buy/confirm",
            query
          })
        } else {
          ElMessage(res.msg)
        }
      })




    };

    const isOutputing = ref(false); //是否点击导出产品
    const outPut = () => { //导出已选产品
      if (goodsInfos.value.length == 0) {
        ElMessage(L['请选择要导出的产品']);
      } else {
        if (isOutputing.value) return;
        isOutputing.value = true;
        let param = {
          goodsInfos: goodsInfos.value.join(',')
        }
        proxy
          .$get('v3/member/front/project/export', param, 'blob')
          .then(res => {
            let tmp = new Blob([res], { type: 'application/vnd.ms-excel' })
            const aLink = document.createElement('a');
            document.body.appendChild(aLink);
            aLink.style.display = 'none';
            aLink.href = window.URL.createObjectURL(tmp);
            aLink.setAttribute('download', projectInfo.data.projectName);
            aLink.click();
            document.body.removeChild(aLink);
            ElMessage.success(L['导出成功']);
            isOutputing.value = false;
          })
          .catch(() => {
            ElMessage.error(L['导出失败'])
            isOutputing.value = false;
          })
      }
    };

    const delProduct = (type) => {
      let title = ''
      let param = {};

      if (type == 1) {
        title = L['确定删除选中产品?'];

        let arr = [];
        projectInfo.data.productList.forEach(item => {
          if (item.ischeck) {
            arr.push(item.bindId);
          }
        })
        if (arr.length == 0) {
          ElMessage.warning(L['请选择要删除的产品']);
          return;
        }
        param.bindIds = arr.join(',');

      } else {
        title = L['确定清空无效产品?'];
        param.bindIds = invalidIds.value;
      }

      ElMessageBox.confirm(title, L['提示'], {
        confirmButtonText: L['确定'],
        cancelButtonText: L['取消'],
        type: 'warning',
      }).then(() => {
        proxy.$post('v3/member/front/project/delGoods', param).then(res => {
          if (res.state == 200) {
            ElMessage.success(res.msg);
            if (type == 1) {
              projectInfo.data.productList = projectInfo.data.productList.filter(item => {
                return (param.bindIds.indexOf(item.bindId) == -1);
              })
              allCheck.value = false;
              changeSelect();
            } else {
              projectInfo.data.invalidList = [];
              invalidIds.value = '';
            }
          } else {
            ElMessage.error(res.msg);
          }
        })
      })

    };

    const goodsDetail = (productId) => {
      router.push({
        path: '/goods/detail',
        query: { productId }
      })
    };

    onMounted(() => {
      getInfo();
    })

    return {
      L,
      memberInfo,
      projectInfo,
      isEdit,
      projectId,
      getInfo,
      allCheck,
      productInfos,
      editNum,
      editGoodsNum,
      changeAll,
      changeSelect,
      confirmOrder,
      goBuy,
      checkSelect,
      selectNum,
      totalMoney,
      isPaying,
      outPut,
      isOutputing,
      goodsInfos,
      delProduct,
      invalidIds,
      goodsDetail,
      isShowTotal
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/orderProjectDetail.scss";
</style>
<style>
.bd5 .el-table__header,
.bd5 .el-table__body {
  width: 100% !important;
}
</style>